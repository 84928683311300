import VLDField from './VLD.Field';

/**
 * `VLDFieldEmail` Email field creation class, extended from `VLDField`.
 *
 * @module VLDFieldEmail
 * @extends {VLDField}
 */

class VLDFieldEmail extends VLDField {
  /**
   * Before proceeding with `mountField()` method, add additional validation against email regex.
   *
   * @returns {VLDFieldEmail} For chaining.
   * @memberof VLDFieldEmail
   */
  public beforeMount(): VLDFieldEmail {
    // Add validation for fields with regex patterns
    // tslint:disable-next-line:max-line-length
    const emailRegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.addValidationRule(() => emailRegExp.test(this.$field.value));
    return this;
  }
}

export default VLDFieldEmail;
