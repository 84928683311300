import Slider from './Slider';

class SliderDefault extends Slider {
  public updateOptions() {
    return {
      speed: 500,
      autoplay: {
        delay: 3000,
      },
    };
  }
}

export default SliderDefault;
