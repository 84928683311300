import SliderDefault from './Slider.Default';

class SliderFeatured extends SliderDefault {
  public updateOptions() {
    return Object.assign(super.updateOptions(), {
      slidesPerView: 2,
      breakpoints: {
        768: {
          slidesPerView: 3,
        },
      },
    });
  }
}

export default SliderFeatured;
