interface HTMLPlayerElement extends HTMLElement {
  play: () => void;
  pause: () => void;
}

export default class Player {
  $player: HTMLPlayerElement;
  $file: HTMLVideoElement;
  $playerPlay: HTMLElement;
  isPlaying: boolean = false;

  constructor($player) {
    this.playPlayer = this.playPlayer.bind(this);
    this.pausePlayer = this.pausePlayer.bind(this);

    this.$player = $player;
    this.$file = $player.querySelector('[data-player-file]');
    this.$playerPlay = $player.querySelector('[data-player-play]');

    this.$playerPlay.addEventListener('click', this.playPlayer);

    this.$file.addEventListener('play', () => {
      this.isPlaying = true;
      this.$player.classList.add('is-playing');
      window.addEventListener('scroll', this.pausePlayer);
    });

    this.$file.addEventListener('pause', () => {
      this.isPlaying = false;
      this.$player.classList.remove('is-playing');
      window.removeEventListener('scroll', this.pausePlayer);
    });

    this.$player.play = this.playPlayer;
    this.$player.pause = this.pausePlayer;

    this.$file.onclick = this.pausePlayer;
  }

  playPlayer() {
    if (!this.isPlaying) this.$file.play();
  }

  pausePlayer() {
    if (this.isPlaying) this.$file.pause();
  }
}
