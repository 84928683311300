import { IVLDField } from './VLD.d';

import VLDField from './VLD.Field';

/**
 * `VLDFieldRadio` Radio field creation class, extended from `VLDField`.
 *
 * @module VLDFieldRadio
 * @extends {VLDField}
 */

class VLDFieldRadio extends VLDField {
  // A nodelist of sibling radio controls with same name attirbute:
  $radioControls: NodeListOf<HTMLInputElement> = this.$field.form.elements[this.$field.name];

  /**
   * Function for checking if field has value.
   * It acts as sort of a getter.
   *
   * OVERRIDE:
   * In radio case, it checks not for `value.length`, but for `checked` property.
   *
   * @returns {boolean} State: true or false.
   * @memberof VLDFieldRadio
   */

  public hasValue(): boolean {
    return Boolean(this.$field.checked);
  }

  /**
   * Update field object's value and start validation.
   *
   * OVERRIDE:
   * In radio case, remove all validation rules from all the siblings with the same name.
   * Then set their state to valid and without value.
   * And only then update state of target field with `hasValue: true`.
   *
   * @memberof VLDFieldRadio
   */

  public handleChange() {
    // Unset value for all the sibling elements.
    [].forEach.call(this.$radioControls, ($radio: IVLDField) => {
      return $radio.removeValidationRules().updateState({
        hasValue: false,
        isPristine: false,
        hasError: false,
        isValid: true,
      });
    });
    this.updateState({ hasValue: true });
  }
}

export default VLDFieldRadio;
