import Parallax from './Parallax';

import $$ from '../../toolkit/$$';

/**
 * ParallaxsController initializer factory.
 *
 * @module ParallaxsController
 */

class ParallaxsController {
  /**
   * Creates an instance of ParallaxsController.
   *
   * @memberof ParallaxsController
   * @returns An object of initialized ParallaxsController.
   */

  // A NodeList of '[data-parallax]' HTML Elements:
  public $$parallaxs: NodeListOf<HTMLElement>;

  // An array of mounted parallaxs.
  public parallaxs: any[];

  public activeTrackers: any = {};

  // Intesection observer instead of scroll monitor:
  public observer: IntersectionObserver;
  /**
   * Creates an instance of ParallaxsController.
   * If there are any `[data-parallax]` in DOM, call `mountParallaxs()`.
   *
   * @memberof ParallaxsController
   */
  constructor() {
    this.$$parallaxs = $$('[data-parallax]');
    if (this.$$parallaxs[0]) this.mountParallaxs();
  }

  /**
   * Loops through all `$$parallaxs` and depending on type, call Parallax constructors.
   *
   * @returns {ParallaxsController} For chaining metods
   * @memberof ParallaxsController
   */
  mountParallaxs() {
    this.beforeMount();

    this.parallaxs = [].map.call(this.$$parallaxs, ($parallax, i) => {
      return new Parallax($parallax, this, i);
    });

    this.afterMount();

    return this;
  }

  /**
   * Extra function to call BEFORE mounting all parallaxs.
   *
   * @returns {ParallaxsController} For chaining.
   * @memberof ParallaxsController
   */
  public beforeMount() {
    return this;
  }

  /**
   * Extra function to call AFTER mounting all parallaxs.
   *
   * @returns {ParallaxsController} For chaining.
   * @memberof ParallaxsController
   */
  public afterMount() {
    return this;
  }
}

export default ParallaxsController;
