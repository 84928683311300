import mapStyles from '../../../js/misc/mapStyles';

class Map {
  // Initialized map object.
  public map: any;
  // Unique name for the map.
  public name: string;
  // An array of infoWindows.
  public infoWindows: any[] = [];
  // Zoom level with default value.
  public zoom: number = 10;
  // Center Latitude with default value.
  public centerLat: number = 56;
  // Center Longitude with default value.
  public centerLng: number = 24;
  // General map settings passed via data- attributes
  public mapData: any = null;
  // An array of markers.
  public markers: any[] = [];

  public $map: HTMLElement;
  public mapsController: any;
  public i: number;
  public gMaps: any;

  /**
   * Creates an instance of Map. Assigns class variables and iniaitate gMaps module.
   * @param {HTMLElement} $map HTML element of the Map's target.
   * @param {*} gMaps Imported Google Maps module.
   * @param {*} mapsController Map's parent initialization function.
   * @param {number} i Map's initializator's place in array.
   * @memberof Map
   */

  constructor($map: HTMLElement, gMaps: any, mapsController: any, i: number) {
    this.$map = $map;
    this.gMaps = gMaps;
    this.mapsController = mapsController;
    this.i = i;

    this.mapData = $map.dataset;
    this.name = this.mapData.map;

    if (this.name.length) {
      if (this.mapData) {
        if (this.mapData['mapZoom']) this.zoom = parseFloat(this.mapData['mapZoom']);
        if (this.mapData['mapCenterLat']) this.centerLat = parseFloat(this.mapData['mapCenterLat']);
        if (this.mapData['mapCenterLng']) this.centerLng = parseFloat(this.mapData['mapCenterLng']);

        this.map = new gMaps.Map(this.$map, {
          zoom: this.zoom,
          center: { lat: this.centerLat, lng: this.centerLng },
          styles: mapStyles,
        });

        this.afterMount();
      } else {
        console.error(`window[maps]['${this.name}'] was not provided for data-map="${this.name}"`);
      }
    } else {
      console.error('Please provide an attribute for [data-map]');
    }
  }

  /**
   * Extra function to call after initialization.
   *
   * @returns {Map} For chaining.
   * @memberof Map
   */
  public afterMount() {
    if (this.mapData.mapMarkers && this.mapData.mapMarkers.length) this.addMarkers();
    return this;
  }

  /**
   * Add markers from backend locations.
   *
   * @returns {Map} For chaining.
   * @memberof Map
   */
  public addMarkers() {
    this.openInfoWindow = this.openInfoWindow.bind(this);

    this.markers = JSON.parse(this.mapData.mapMarkers).map((marker, i) => {
      const markerPosition = new this.gMaps.LatLng(marker.lat, marker.lng);
      const mapsMarker = new this.gMaps.Marker({
        position: markerPosition,
        map: this.map,
        icon: marker.pin,
      });

      marker.content && mapsMarker.addListener('click', () => this.openInfoWindow(i));

      const mapsInfoWindow = marker.content
        ? new this.gMaps.InfoWindow({ content: this.renderInfoWindow(marker.content) })
        : null;

      return {
        marker: mapsMarker,
        infoWindow: mapsInfoWindow ? mapsInfoWindow : null,
      };
    });
    return this;
  }

  /**
   * Convert marker's content into HTML.
   * Should be overriden depending on project.
   *
   * @returns A string of HTML to be rendered into infoWindow.
   * @memberof Map
   */
  public renderInfoWindow(content) {
    const html = `
      <div class="infowindow">${content}</div>
    `;
    return html;
  }

  /**
   * Loop through all the infoWindows and close them.
   * Open the one which's `i` index matches inner loops' `m` index.
   *
   * @returns google options object
   * @memberof Map
   */
  public openInfoWindow(i) {
    this.markers.forEach((marker, m) => {
      marker.infoWindow && m === i
        ? marker.infoWindow.open(this.map, marker.marker.l)
        : marker.infoWindow.close();
    });
  }
}

export default Map;
