import { IVLDForm, IVLDField, IVLDFieldStateProperty } from './VLD.d';

import kebabize from '../../toolkit/kebabize';

/**
 * `VLDField` field creation class - to be extended for various form elements.
 *
 * @module VLDField
 */

class VLDField {
  $field: IVLDField;
  initialValue: string;
  validationRules: any[] = [];
  vldForm: IVLDForm;

  /**
   * Constructor takes in HTML form input element and <form> element as parmeters.
   *
   * @param {(HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement)} $field
   * @param {IVLDForm} vldForm
   * @memberof VLDField
   */
  constructor($field: HTMLInputElement | HTMLSelectElement, vldForm?: IVLDForm) {
    // console.log($field, $field.name);
    // Assing class variables;
    this.$field = $field as IVLDField;
    this.vldForm = vldForm as IVLDForm;
    this.$field.$parent = this.$field.parentElement;
    this.initialValue = this.$field.value;

    // Bind required functions to constructor for accessing it's methods from outside.
    this.applyValidators = this.applyValidators.bind(this);
    this.$field.removeValidationRules = this.removeValidationRules.bind(this);
    this.$field.updateState = this.updateState.bind(this);

    // console.log(this.$field);

    // Set initial values:
    this.setInitialValues();

    // Mount field:
    this.mountField();
  }

  /**
   * Set initial values and defaul tstate.
   *
   *
   * @returns {VLDField} For chaining.
   * @memberof VLDField
   */
  public setInitialValues(): VLDField {
    const initialState = {
      hasValue: false, // <-- Initially set to false, we'll check for that later.
      hasError: false,
      isPristine: true,
      isFocused: false,
      isValid: true,
    };

    this.$field.value = this.initialValue;
    this.$field.state = initialState;

    // Do the initial check for hasValue.
    this.updateState({ hasValue: this.hasValue() });

    return this;
  }

  /**
   * Function for checking if field has value.
   * It acts as sort of a getter and it's different for checkboxes/radios.
   *
   * @returns {boolean} State: true or false.
   * @memberof VLDField
   */
  public hasValue(): boolean {
    return this.$field.value.length > 0;
  }

  /**
   * Add event listeners and other HTML updates/injections if necessary.
   * This is to be overriden extended.
   *
   * @returns {VLDField} For chaining.
   * @memberof VLDField
   */

  public mountField(): VLDField {
    // Call beforeMount() method before mounting field.
    this.beforeMount();

    // Add validation rules.
    this.addValidators();

    // Add Event Listeners.
    this.addListeners();

    // Call afterMount() method before mounting field.
    this.afterMount();

    return this;
  }

  /**
   * This is to be overriden with custom extends to add extra custom functionality.
   *
   * @returns {VLDField} For chaining.
   * @memberof VLDField
   */
  public beforeMount(): VLDField {
    return this;
  }

  /**
   * This is to be overriden with custom extends to add extra custom functionality.
   *
   * @returns {VLDField} For chaining.
   * @memberof VLDField
   */
  public afterMount(): VLDField {
    return this;
  }

  /**
   * Add validation rules.
   * Validation rules are simple functions which return true || false based on input value.
   * They are only added (pushed to `validationRules` array) to constructor if HTML input
   * has certain attribute.
   * If HTML atribute has a value (i.e. maxlength="5"), this value is used as parameter.
   *
   * Also, validation rule can be any function which returns true || false.
   *
   * @private
   * @returns {VLDField} For chaining.
   * @memberof VLDField
   */
  private addValidators(): VLDField {
    const minlength = Number(this.$field.getAttribute('minlength'));
    const maxlength = Number(this.$field.getAttribute('maxlength'));
    const pattern = this.$field.getAttribute('pattern');

    // Add validation for required fields
    if (this.$field.required) this.validationRules.push(() => (this.hasValue() ? true : false));

    // Add validation for fields with minlength
    if (minlength) this.addValidationRule(() => minlength <= this.$field.value.length);

    // Add validation for fields with maxlength
    if (maxlength) this.addValidationRule(() => maxlength >= this.$field.value.length);

    // Add validation for fields with regex patterns
    if (pattern) this.addValidationRule(() => new RegExp(pattern).test(this.$field.value));

    return this;
  }

  /**
   * Add validation functions to `validationRules` array.
   *
   * Note: before checking for validity, first check if field has value.
   * If it does not, return true. This means it sort of passes validation.
   * However it will fail on the first rule in array - checking for `required`.
   *
   * So, if email field is:
   * 1. Not required but has wrong value will return `false`
   * 2. Required and has no value will return `true` from this function, but `false` in general.
   *
   * @param {*} validationRule Simple function, whics returns true or false.
   * @memberof VLDField
   */
  public addValidationRule(validationRule) {
    this.validationRules.push(() => (this.hasValue() ? validationRule() : true));
  }

  /**
   * Remove validation rules.
   *
   * Note: after calling this method, validation will always return `true`;
   *
   * @returns {VLDField} For chaining.
   * @memberof VLDField
   */
  public removeValidationRules(): VLDField {
    this.validationRules = [];
    return this;
  }

  /**
   * Add event listeners to essential field state changes.
   *
   * Note: value changes are tracked with both `change` and `keyup` events.
   *
   * @private
   * @returns {VLDField} For chaining.
   * @memberof VLDField
   */
  private addListeners() {
    // Bind event handling functions to constructor's `this`.
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleChange = this.handleChange.bind(this);

    // Named functions are used, so we are able to call `removeEventLister`.
    // Add those listenres.
    // this.$field.addEventListener('focus', this.handleFocus);
    // this.$field.addEventListener('blur', this.handleBlur);
    // this.$field.addEventListener('change', this.handleChange);
    // this.$field.addEventListener('keyup', this.handleChange);
    this.$field.addEventListener('focus', () => this.handleFocus());
    this.$field.addEventListener('blur', () => this.handleBlur());
    this.$field.addEventListener('change', () => this.handleChange());
    this.$field.addEventListener('keyup', () => this.handleChange());

    // this.$field.addEventListener('keyup', () => this.handleChange());

    return this;
  }

  /**
   * Handle field focus event (add corresponding class names)
   *
   * @memberof VLDField
   */
  public handleFocus() {
    this.updateState({ isFocused: true });
  }

  /**
   * Handle field blur event.
   * Mark field as not pristine (`isPristine: false`) upon blur, so it could start being validated.
   * Then start validating.
   *
   * @memberof VLDField
   */
  public handleBlur() {
    this.updateState({
      isFocused: false,
      isPristine: false,
      isValid: this.applyValidators(),
      hasError: !this.applyValidators(),
    });
  }

  /**
   * Update field object's value. If it is not pristine, start validation.
   *
   * @memberof VLDField
   */
  public handleChange() {
    this.updateState({
      hasValue: this.hasValue(),
      isValid: this.applyValidators(),
      hasError: !this.applyValidators() && !this.$field.state.isPristine,
    });
  }

  /**
   * Update field's state and correspinding class names.
   *
   * It acceps takes incoming state as a parameter,
   * compares it to previous state and only updates those properties which differ.
   * Only then the constructor update is applied and classList update is triggered.
   *
   * Class names are contructed by converting `camelCase` property to `kebab-case`.
   * So `isValid` becomes `is-valid`.
   *
   * It can be forced to bypass checking for previous state and update imperatively.
   *
   * @param {IVLDFieldStateProperty} stateUpdate - State update values object.
   * @param {boolean} [force] - An Optional parameter to force the update.
   * @returns {VLDField} For chaining.
   * @memberof VLDField
   */
  public updateState(stateUpdate: IVLDFieldStateProperty, force?: boolean): VLDField {
    // Check if curent state (except prototype) is different than updated state. Or force it:
    if (JSON.stringify(this.$field.state) !== JSON.stringify(stateUpdate) || force) {
      // Loop through all updated properties:
      for (const key in stateUpdate) {
        // If previous state property value doesn't match with update. Or force it:
        if (this.$field.state[key] !== stateUpdate[key] || force) {
          // Do the className update!
          // Convert state variable to kebab-case class name:
          const className = kebabize(key);
          this.$field.classList[stateUpdate[key] ? 'add' : 'remove'](className);
          this.$field.$parent.classList[stateUpdate[key] ? 'add' : 'remove'](className);
          this.$field.state[key] = stateUpdate[key];
        }
      }
    }
    return this;
  }

  /**
   * Apply all the validation functions form `validationRules` array, if there are any.
   *
   * @returns {boolean} End value. Defaults to true (`.reduce` initial accumlator `isValid`).
   * @memberof VLDField
   */
  public applyValidators(): boolean {
    return this.validationRules.reduce((isValid, rule) => (isValid ? rule() : false), true);
  }

  /**
   * TODO
   * Using Observer pattern, when we have access to initialized `VLDForm`,
   * we have access to it's methods.
   *
   * So we can call any of them at any methdod in `VLDField` instance.
   * I.E. notify form, when specific field becomes not pristine etc.
   *
   * Not sure what to do with it though.
   *
   * @memberof VLDField
   */
  public notifyForm() {
    console.log(this.vldForm);
  }

  /**
   * Cleanup the DOM changes - remove all classes and listeners.
   *
   * @memberof VLDField
   */
  public unMount() {
    this.setInitialValues();
    this.$field.classList.remove('has-value', 'has-error', 'is-focused');
    this.$field.$parent.classList.remove('has-value', 'has-error', 'is-focused');
    this.$field.removeEventListener('focus', this.handleFocus);
    this.$field.removeEventListener('blur', this.handleBlur);
    this.$field.removeEventListener('change', this.handleChange);
    this.$field.removeEventListener('keyup', this.handleChange);
  }
}

export default VLDField;
