import TweenerDefault from './Tweener.Default';

import $$ from '../../toolkit/$$';

/**
 * TweenersController initializer factory.
 *
 * @module TweenersController
 */

class TweenersController {
  /**
   * Creates an instance of TweenersController.
   *
   * @memberof TweenersController
   * @returns An object of initialized TweenersController.
   */

  // A NodeList of '[data-tweener]' HTML Elements:
  public $tweeners: NodeListOf<HTMLElement>;

  // An array of mounted tweeners.
  public tweeners: any[];

  // An array of active tweeners in a queue.
  public tweenersQueue: any[] = [];

  // Default suspend between concurrent tweens.
  public isTweening: boolean = false;

  // Intesection observers to monitor scroll
  public observer: IntersectionObserver;
  public changeObserver: IntersectionObserver;

  // Default suspend between concurrent tweens.
  public DEFAULT_SUSPEND: number = 150;

  /**
   * Creates an instance of TweenersController.
   * If there are any `[data-tweener]` in DOM, call `mountTweeners()`.
   *
   * @memberof TweenersController
   */
  constructor() {
    this.$tweeners = $$('[data-tweener]');
    if (this.$tweeners[0]) this.mountTweeners();
  }

  /**
   * Loops through all `$tweeners` and depending on type, call Tweener constructors.
   *
   * @returns {TweenersController} For chaining metods
   * @memberof TweenersController
   */
  mountTweeners() {
    // previous $tweener's position from top
    let lastTop = null;
    // default suspend before starting new animation
    let suspend = 0;

    this.beforeMount();

    this.observer = new IntersectionObserver(
      (entries: any) => {
        for (const entry of entries) {
          if (entry.isIntersecting) this.onTweenerEnter(entry.target);
        }
      },
      { rootMargin: '20px', threshold: 0 },
    );

    this.changeObserver = new IntersectionObserver(
      (entries: any) => {
        for (const entry of entries) {
          if (entry.isIntersecting) this.onBeforeTweenerEnter(entry.target);
        }
      },
      { rootMargin: '70px', threshold: 0 },
    );

    this.tweeners = [].map.call(this.$tweeners, ($tweener, i) => {
      const type = $tweener.dataset.tweener;
      const fromTop = $tweener.getBoundingClientRect().top;
      const tweenerSuspend = $tweener.dataset.tweenerSuspend;
      if (fromTop === lastTop) {
        suspend += tweenerSuspend ? Number(tweenerSuspend) : this.DEFAULT_SUSPEND;
      } else {
        suspend = tweenerSuspend ? Number(tweenerSuspend) : 0;
        lastTop = fromTop;
      }
      // if (type === 'scene') $tweener.tweener = new TweenerScene($tweener, this, i, suspend);
      // else $tweener.tweener = new TweenerDefault($tweener, this, i, suspend);
      $tweener.tweener = new TweenerDefault($tweener, this, i, suspend);
      this.observer.observe($tweener);
      this.changeObserver.observe($tweener);
      return $tweener.tweener;
    });

    this.afterMount();

    return this;
  }

  /**
   * Extra function to call BEFORE mounting all tweeners.
   *
   * @returns {TweenersController} For chaining.
   * @memberof TweenersController
   */
  public beforeMount() {
    return this;
  }

  /**
   * Extra function to call AFTER mounting all tweeners.
   *
   * @returns {TweenersController} For chaining.
   * @memberof TweenersController
   */
  public afterMount() {
    return this;
  }

  /**
   * A callback function fired just after tweener.open() function.
   *
   * @param {*} tweener A mounted tweener object.
   * @returns {Tweener} For chaining.
   * @memberof Tweener
   */
  public onTweenerStart(tweener) {
    this.isTweening = true;
    return this;
  }

  /**
   * A callback function fired just after tweener.close() function.
   * Controls hashe/href.
   *
   * @returns {Tweener} For chaining.
   * @memberof Tweener
   */
  public onTweenerEnd() {
    this.isTweening = false;
    return this;
  }

  public onBeforeTweenerEnter($tweener) {
    $tweener.classList.add('_should-tween');
    console.log('$tweener.classList.add(_should-tween);');
    // $tweener.style.willChange = 'transform, opacity';
    return this;
  }

  /**
   * Watcher callback whi
   *
   * @returns {TweenersController} For chaining.
   * @memberof TweenersController
   */
  public onTweenerEnter($tweener) {
    this.observer.unobserve($tweener);
    this.changeObserver.unobserve($tweener);
    this.tweenersQueue.push($tweener.tweener);
    if (this.tweenersQueue.length === 1) this.enqueueTween();
    return this;
  }

  /**
   * Watcher callback whi
   *
   * @returns {TweenersController} For chaining.
   * @memberof TweenersController
   */
  public enqueueTween() {
    this.tweenersQueue[0].start();
    this.tweenersQueue.shift();
    if (this.tweenersQueue.length) this.enqueueTween();
    return this;
  }
}

export default TweenersController;
