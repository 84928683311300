import animejs from 'animejs';

// import { IVLDMountedForms, IVLDForm } from '../modules/vld/VLD';

import $$ from '../toolkit/$$';
import TweenerScene from '../modules/tweeners/Tweener.Scene';

import VLDField from '../modules/vld/VLD.Field';
import VLDFieldCheckBox from '../modules/vld/VLD.Field.CheckBox';
import VLDFieldEmail from '../modules/vld/VLD.Field.Email';
import VLDFieldRadio from '../modules/vld/VLD.Field.Radio';
import VLDFieldSelect from '../modules/vld/VLD.Field.Select';

export default class Wizard {
  // initialized tweener scene
  DURATION: number = 15;

  $minutes: HTMLElement;
  $seconds: HTMLElement;

  scene: any;
  timer: any;

  modal: any;

  room: any;
  time: any;
  price: any;

  $wizard: HTMLElement;
  $bar: HTMLElement;
  $stepper: HTMLElement;
  $form: HTMLFormElement;
  $scroller: HTMLElement;
  $total: HTMLElement;

  $room: HTMLInputElement;
  $time: HTMLInputElement;
  $price: HTMLInputElement;

  $$steps: NodeListOf<HTMLElement>;
  $$triggers: NodeListOf<HTMLElement>;
  $$visuals: NodeListOf<HTMLElement>;

  activeStep: number;
  $activeStep: HTMLElement;
  $activeTrigger: HTMLElement;

  $next: HTMLElement;
  $back: HTMLElement;

  $$paymentTypeContents: NodeListOf<HTMLElement>;
  $$paymentTypeControls: NodeListOf<HTMLInputElement>;
  activePaymentType: string;

  $$banks: NodeListOf<HTMLInputElement>;
  activeBank: string;

  steps: any;

  constructor(room, time, price, modal) {
    this.modal = modal;
    this.room = room;
    this.time = time;
    this.price = price;
    if ($$('[data-wizard]')[0]) this.init();
  }

  init() {
    // const $wizardVisual = $$(`[data-wizard-visual="${room}"]>[data-tweener="wizard"]`)[0];
    // const $tweener = $$(`[data-wizard-visual="${room}"]`)[0].querySelector(
    //   '[data-tweener="wizard"]',
    // );

    this.$wizard = $$('[data-wizard]')[0];
    this.$bar = this.$wizard.querySelector('[data-wizard-bar]');
    this.$stepper = this.$wizard.querySelector('[data-wizard-stepper]');
    this.$form = this.$wizard.querySelector('[data-wizard-form]');
    this.$scroller = this.$wizard.querySelector('[data-wizard-scroller]');
    this.$total = this.$wizard.querySelector('[data-wizard-total]');

    this.$room = this.$wizard.querySelector('[name="wizard-room"]');
    this.$time = this.$wizard.querySelector('[name="wizard-time"]');
    this.$price = this.$wizard.querySelector('[name="wizard-price"]');

    this.$$steps = this.$wizard.querySelectorAll('[data-wizard-step]');
    this.$$triggers = this.$wizard.querySelectorAll('[data-wizard-trigger]');
    this.$$visuals = this.$wizard.querySelectorAll('[data-wizard-visual]');
    this.$$paymentTypeContents = this.$wizard.querySelectorAll('[data-wizard-payment-type]');
    this.$$paymentTypeControls = this.$wizard.querySelectorAll('[name="wizard-payment-type"]');
    this.$$banks = this.$wizard.querySelectorAll('[name="wizard-bank"]');

    this.$next = this.$wizard.querySelector('[data-wizard-next]');
    this.$back = this.$wizard.querySelector('[data-wizard-back]');

    this.$next.addEventListener('click', () => this.goToStep(this.activeStep + 1));
    this.$back.addEventListener('click', () => this.goToStep(this.activeStep - 1));

    this.$form.addEventListener('submit', e => this.onWizardSubmit(e));

    this.steps = [].map.call(this.$$steps, ($step: HTMLElement, index: number) => {
      const id = Number($step.dataset.wizardStep);
      const $$fields = $step.querySelectorAll('input, textarea, select');
      const $visual = this.$wizard.querySelector(`[data-wizard-visual="${index}"]`);

      const fields = [].map.call($$fields, ($field: HTMLInputElement, i: number) => {
        if ($field.type === 'checkbox') return new VLDFieldCheckBox($field, this as any);
        if ($field.type === 'email') return new VLDFieldEmail($field, this as any);
        if ($field.type === 'radio') return new VLDFieldRadio($field, this as any);
        if ($field.type === 'select-one') return new VLDFieldSelect($field, this as any);
        return new VLDField($field, this as any); // <-- default return
      });

      // const fields = [].map.call($$fields, ($field: HTMLInputElement, i: number) => {
      //   let field;
      //   if ($field.type === 'checkbox') field = new VLDFieldCheckBox($field, this as any);
      //   if ($field.type === 'email') field = new VLDFieldEmail($field, this as any);
      //   if ($field.type === 'radio') field = new VLDFieldRadio($field, this as any);
      //   if ($field.type === 'select-one') field = new VLDFieldSelect($field, this as any);
      //   else field = new VLDField($field, this as any); // <-- default return
      //   return field;
      // });

      return {
        id,
        $step,
        fields,
        $visual,
        $trigger: this.$$triggers[index],
        isActive: false,
      };
    });

    [].forEach.call(this.$$triggers, ($trigger: HTMLElement) => {
      $trigger.addEventListener('click', () => {
        const id = $trigger.dataset.wizardTrigger;
        if ($trigger.classList.contains('can-visit') && this.activeStep.toString() !== id) {
          this.goToStep(Number(id));
        }
      });
    });

    [].forEach.call(this.$$paymentTypeControls, ($radio: HTMLInputElement) => {
      $radio.addEventListener('change', e => this.handlePaymentTypeChange(e));
    });

    [].forEach.call(this.$$banks, ($bank: HTMLInputElement) => {
      $bank.addEventListener('change', e => this.handleBankChange(e));
    });

    this.start();
  }

  start() {
    const stepperHeight = this.$stepper.clientHeight;
    if (window.innerWidth > 768) animejs.set(this.$stepper, { minHeight: stepperHeight });

    [].forEach.call(this.$$steps, $step => $step.classList.add('is-ready'));

    this.activeStep = 0;
    this.$activeStep = this.$$steps[0];
    this.$activeTrigger = this.$$triggers[0];

    this.activePaymentType = this.$form.elements['wizard-payment-type'].value;
    this.activeBank = this.$form.elements['wizard-bank'].value;

    this.$room.value = this.room;
    this.$time.value = this.time;
    this.$price.value = this.price;
    this.$total.innerText = this.price;

    animejs.set(this.$bar, { clip: `rect(0px, ${this.$activeTrigger.clientWidth}px, auto, 0px)` });

    this.startTimer();

    return this;
  }

  goToStep(step) {
    if (!this.checkIfCanGo(step)) return this;

    const barRect = this.$bar.getBoundingClientRect();

    const { $step, $visual, $trigger } = this.steps[step];
    const triggerRect = $trigger.getBoundingClientRect();
    const triggerFromLeft = triggerRect.left - barRect.left;
    const $activeVisual: HTMLElement = this.$wizard.querySelector(
      `[data-wizard-visual="${this.activeStep}"]`,
    );

    $step.classList.add('is-translating');

    animejs({
      targets: this.$bar,
      clip: `rect(0px, ${triggerFromLeft + triggerRect.width}px, auto, ${triggerFromLeft}px)`,
      easing: 'spring(.8, 80, 8, 0)',
    });

    animejs({
      targets: $step,
      translateX: [step < this.activeStep ? '-100%' : '100%', 0],
      easing: 'easeOutQuad',
      duration: 500,
      complete: () => this.setActiveStep(step),
    });

    if ($activeVisual) {
      animejs({
        targets: $activeVisual,
        translateY: [0, '100%'],
        easing: 'easeOutQuad',
        duration: 400,
        complete: () => ($activeVisual.style.opacity = ''),
      });
    }

    if ($visual) {
      $visual.style.opacity = 1;
      animejs({
        targets: $visual,
        translateY: ['100%', 0],
        easing: 'easeOutQuad',
        duration: 400,
      });
    }

    animejs({
      targets: this.$scroller,
      scrollLeft: triggerFromLeft,
      duration: 300,
      easing: 'easeOutQuad',
    });

    animejs({
      targets: this.modal.$modal,
      scrollTop: 0,
      duration: 500,
      easing: 'easeOutQuad',
    });

    return this;
  }

  setActiveStep(step) {
    this.$activeStep.classList.remove('is-active');
    this.$activeTrigger.classList.add('can-visit');
    this.steps[this.activeStep].isActive = false;
    this.steps[step].isActive = true;

    this.activeStep = step;
    this.$activeStep = this.$$steps[step];
    this.$activeTrigger = this.$$triggers[step];
    this.$wizard.dataset.wizardActiveStep = this.activeStep.toString();
    this.$$steps[step].classList.remove('is-translating');
    this.$$steps[step].classList.add('is-active');

    return this;
  }

  checkIfCanGo(step) {
    const $errorField = this.getErrorField(this.activeStep);
    let canGo = step > -1 && step < this.$$steps.length;
    if ($errorField && step > this.activeStep) {
      $errorField.focus();
      canGo = false;
    }
    // canGo = true;
    return canGo;
  }

  public getErrorField(step) {
    return this.steps[step].fields.reduce(($fieldEl, field) => {
      field.updateState({
        isPristine: false,
        isValid: field.applyValidators(),
        hasError: !field.applyValidators(),
      });
      return (!field.applyValidators() && !$fieldEl ? field.$field : $fieldEl) as boolean;
    }, null);
  }

  public onWizardSubmit(e) {
    if (this.activeStep !== this.$$steps.length - 1) e.preventDefault();
  }

  public handlePaymentTypeChange(e) {
    this.activePaymentType = e.target.value;
    [].forEach.call(this.$$paymentTypeContents, ($content: HTMLElement) => {
      const type = $content.dataset.wizardPaymentType;
      $content.classList[type === this.activePaymentType ? 'add' : 'remove']('is-active');
    });
  }

  public handleBankChange(e) {
    this.activeBank = e.target.value;
  }

  startTimer() {
    let totalSeconds = this.DURATION * 60;
    let minutes = this.DURATION;
    let seconds = 0;

    this.$minutes = this.$wizard.querySelector('[data-wizard-minutes]');
    this.$seconds = this.$wizard.querySelector('[data-wizard-seconds]');

    const flipMinute = () => {
      totalSeconds -= 1;
      seconds = totalSeconds % 60;
      minutes = (totalSeconds - seconds) / 60;

      this.$minutes.innerText = minutes.toString();
      this.$seconds.innerText = seconds < 10 ? `0${seconds.toString()}` : seconds.toString();

      if (minutes === 0 && seconds === 0) {
        clearTimeout(this.timer);
        this.modal.close();
      } else {
        this.timer = setTimeout(() => flipMinute(), 1000);
      }
    };

    flipMinute();
  }
}
