import animejs from 'animejs';

import { easeInOut } from '../toolkit/ease';
import getRandomInt from '../toolkit/getRandomInt';

const ease = (progress, factor) => easeInOut(2)(progress) * factor * 4;

interface HTMLElementWithTimeline extends HTMLElement {
  timeline: any;
  play: () => void;
}

export default class Heading {
  $heading: HTMLElementWithTimeline;

  symbolAnimations: any[] = [];

  timeline: any;

  $symbols: any[] = [];

  constructor($heading) {
    const lines: string[] = $heading.innerHTML.split('<br>');
    this.play = this.play.bind(this);
    this.$heading = $heading;
    this.timeline = animejs.timeline({ autoplay: false });
    this.$heading.innerHTML = '';
    this.setupLines(lines).forEach($line => this.$heading.append($line));
  }

  setupLines(lines) {
    return lines.map((line: string, l: number) => {
      let cleanLine = line;
      const $lineSpan: HTMLSpanElement = document.createElement('span');
      while (cleanLine.indexOf('  ') > -1) cleanLine = cleanLine.replace('  ', ' ');
      this.setupSymbols(cleanLine.split(''), l).forEach($symbol => $lineSpan.appendChild($symbol));
      $lineSpan.classList.add('heading__line');
      return $lineSpan;
    });
  }

  setupSymbols(symbols, lineIndex) {
    const waveFactor: number = getRandomInt(8, 12);
    const scaleFactor: number = getRandomInt(6, 20);
    return symbols.map((symbol: string, s: number) => {
      const $symbolSpan: HTMLSpanElement = document.createElement('span');
      const pushY: number = -ease((s + 0.5) / symbols.length / 1.5, waveFactor) + waveFactor;
      const scaleY: number = 1 - (scaleFactor / 1000) * (s + 1);
      const skewY: number = (pushY - waveFactor) / 4;
      const symbolDelay: number = s * 50;
      const lineDelay: number = lineIndex * 300;
      const delay: number = symbolDelay + lineDelay;
      const animation = {
        skewY,
        scaleY,
        delay,
        targets: $symbolSpan,
        opacity: [0, 1],
        translateY: [`${800}%`, `${pushY}%`],
        translateX: [s * -10, 0],
        easing: 'spring(1, 80, 10, 0)',
      };
      $symbolSpan.classList.add('heading__symbol');
      // $symbolSpan.style.willChange = 'transform, opacity';
      $symbolSpan.setAttribute('data-heading-symbol', '');
      $symbolSpan.innerHTML = symbol === ' ' ? '&nbsp;' : symbol;
      this.symbolAnimations.push({ ...animation });
      this.timeline.add({ ...animation }, 0);
      return $symbolSpan;
    });
  }

  play() {
    this.timeline.play();
  }
}
