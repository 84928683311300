/**
 * USAGE: camelize('string-input') or  camelize('string_input')
 * OUTPUT: 'stringInput'
 * @param {string} string String in kebab-case or snake_case format.
 * @returns String in camelCase format.
 */

export default function camelize(string: string) {
  return string
    .toLowerCase()
    .replace(/_([a-z])/g, m => m.toUpperCase())
    .replace(/-([a-z])/g, m => m.toUpperCase())
    .replace(/_/g, '')
    .replace(/-/g, '');
}
