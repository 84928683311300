import $$ from '../../toolkit/$$';

class Modal {
  // Initialized modal.
  public modal: any;
  // Modal active triggers.
  public $triggers: NodeListOf<HTMLElement>;
  // Modal close triggers.
  public $closes: NodeListOf<HTMLElement>;
  // Modal's unique ID.
  public id: string = '';
  // Modal's state, not active by default.
  public isActive: boolean = false;
  // HTML video element for autoplay
  public $video: HTMLVideoElement;

  public $modal: HTMLElement;
  public modalsController: any;
  public i: number;

  /**
   * Creates an instance of Modal. Assigns class variables.
   * @param {HTMLElement} $modal HTML element of the Modal's target;
   * @param {*} modalsController Modal's parent initialization funtion
   * @param {number} i Modal's initializator's place in array
   * @memberof Modal
   */
  constructor($modal: HTMLElement, modalsController: any, i: number) {
    this.modalsController = modalsController;
    this.i = i;
    this.$modal = $modal;
    this.id = $modal.dataset.modal;

    this.onMount();
  }

  /**
   * Get all triggers and add event listeners to them.
   *
   * @returns {Modal} For chaining.
   * @memberof Modal
   */
  public onMount() {
    this.beforeMount();

    this.$triggers = $$(`[data-modal-trigger="${this.id}"]`);
    this.$closes = this.$modal.querySelectorAll('[data-modal-close]');
    this.$video = this.$modal.querySelector('video');

    if (this.$triggers[0]) {
      [].forEach.call(this.$triggers, ($trigger: HTMLElement) => {
        $trigger.addEventListener('click', e => this.handleTriggerClick(e));
      });
    }

    if (this.$closes[0]) {
      [].forEach.call(this.$closes, ($close: HTMLElement) => {
        $close.addEventListener('click', () => this.close());
      });
    }

    this.createOpenEvent();
    this.afterMount();

    return this;
  }

  /**
   * Open modal. Fired on `$triggers` click or from `ModalController`.
   * Emit `onModalOpen()` on parent controller with itself as parameter.
   * Update current modal - add classes, animate, play with inner HTML here upon opening.
   * `beforeOpen()` and `afterOpen()` ar more like modifiers to be extended in custom modals.
   *
   * @returns {Modal} For chaining.
   * @memberof Modal
   */
  public open() {
    if (!this.isActive) {
      this.beforeOpen();
      this.onOpen();
    }
    return this;
  }

  public handleTriggerClick(e) {
    this.open();
    return this;
  }

  public onOpen() {
    this.afterOpen();
    return this;
  }

  public onClose() {
    this.afterClose();
    return this;
  }

  /**
   * Close modal. Fired on `$closes` click or from `ModalController`.
   * Emit `onModalClose()` on parent controller with itself as parameter.
   * Update current modal - add classes, animate, play with inner HTML here upon closing.
   * `beforeClose()` and `afterClose()` ar more like modifiers to be extended in custom modals.
   *
   * @returns {Modal} For chaining.
   * @memberof Modal
   */
  public close() {
    this.beforeClose();
    this.onClose();
    return this;
  }

  /**
   * Extra function to call before mounting modal.
   * Used too hook in extra functionality when extending.
   *
   * @returns {Modal} For chaining.
   * @memberof Modal
   */
  public beforeMount() {
    return this;
  }

  /**
   * Extra function to call after mounting modal.
   * Used too hook in extra functionality when extending.
   *
   * @returns {Modal} For chaining.
   * @memberof Modal
   */
  public afterMount() {
    return this;
  }

  /**
   * Create a custom global event `openModal:MODAL_ID` to be called from anywhere.
   *
   * @returns {Modal} For chaining.
   * @memberof Modal
   */

  public createOpenEvent() {
    const openEvent = document.createEvent('CustomEvent');
    openEvent.initEvent(`openModal:${this.id}`, true, true);
    document.addEventListener(`openModal:${this.id}`, () => this.open(), false);
    return this;
  }

  /**
   * Extra function to call before opening modal.
   * Used too hook in extra functionality when extending.
   *
   * @returns {Modal} For chaining.
   * @memberof Modal
   */
  public beforeOpen() {
    return this;
  }

  /**
   * Extra function to call after opening modal.
   * Used too hook in extra functionality when extending.
   *
   * @returns {Modal} For chaining.
   * @memberof Modal
   */
  public afterOpen() {
    this.isActive = true;
    this.modalsController.onModalOpen(this);
    this.$modal.classList.add('is-active');
    if (this.$video) this.$video.play();
    return this;
  }

  /**
   * Extra function to call before closing modal.
   * Used too hook in extra functionality when extending.
   *
   * @returns {Modal} For chaining.
   * @memberof Modal
   */
  public beforeClose() {
    this.modalsController.onModalClose(this);
    this.$modal.classList.remove('is-active');
    if (this.$video) this.$video.pause();
    return this;
  }

  /**
   * Extra function to call after closing modal.
   * Used too hook in extra functionality when extending.
   *
   * @returns {Modal} For chaining.
   * @memberof Modal
   */
  public afterClose() {
    this.isActive = false;
    return this;
  }
}

export default Modal;
