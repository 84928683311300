class Slider {
  // Initialized slider
  public slider: any;
  // Sensible defauts;
  public options: { [key: string]: any };
  // HTML element of the root of the Slider;
  public $target: HTMLElement;
  // HTML element of Slider's bullets;
  public $bullets: NodeListOf<HTMLElement>;
  // HTML element of Slider's prev trigger;
  public $prev: HTMLElement;
  // HTML element of Slider's next trigger;
  public $next: HTMLElement;
  // currently active slide number
  public activeSlide: number;

  public $slider: HTMLElement;
  public slidersController: any;
  public i: number;
  public swiperModule: any;

  /**
   * Creates an instance of Slider. Assigns class variables and iniaitate Swiper module.
   * @param {HTMLElement} $slider HTML element of the Slider's target;
   * @param {*} swiperModule Imported Swiper module
   * @param {*} slidersController Slider's parent initialization funtion
   * @param {number} i Slider's initializator's place in array
   * @memberof Slider
   */
  constructor($slider: HTMLElement, swiperModule: any, slidersController: any, i: number) {
    this.swiperModule = swiperModule;
    this.slidersController = slidersController;
    this.i = i;
    this.$slider = $slider;
    this.$target = $slider.querySelector('[data-slider-target]');
    this.$prev = $slider.querySelector('[data-slider-prev]');
    this.$next = $slider.querySelector('[data-slider-next]');
    this.$bullets = $slider.querySelectorAll('[data-slider-bullet]');

    this.beforeMount();

    this.slider = new swiperModule(this.$target, this.getOptions());

    this.afterMount();
  }

  /**
   * Extra function to call before initialization.
   *
   * @returns {Slider} For chaining.
   * @memberof Slider
   */
  public beforeMount() {
    return this;
  }

  /**
   * Extra function to call after initialization.
   *
   * @returns {Slider} For chaining.
   * @memberof Slider
   */
  public afterMount() {
    if (this.$bullets[0]) this.addBullets();
    this.autoToggleVideo();
    return this;
  }

  /**
   * Add custom bullets and hook them into Swiper events.
   *
   * @returns {Slider} For chaining.
   * @memberof Slider
   */
  public addBullets() {
    [].forEach.call(this.$bullets, ($bullet, b) => {
      $bullet.addEventListener('click', () => {
        const to = this.options.loop ? b + 1 : b;
        this.slider.slideTo(to, this.options.speed);
        [].forEach.call(this.$bullets, ($b: HTMLElement) => {
          $b.classList[$b === $bullet ? 'add' : 'remove']('is-active');
        });
      });
    });

    this.slider.on('transitionEnd', () => {
      this.activeSlide = this.slider.realIndex;
      [].forEach.call(this.$bullets, ($bullet, b) => {
        if (b === this.slider.realIndex) $bullet.click();
        $bullet.classList[b === this.slider.realIndex ? 'add' : 'remove']('is-active');
      });
    });

    return this;
  }

  /**
   * Automatically toggle play on an active slide video and pause previous slide video.
   *
   * @returns {Slider} For chaining.
   * @memberof Slider
   */
  public autoToggleVideo() {
    this.slider.on('transitionEnd', () => {
      const $video = this.slider.slides[this.slider.activeIndex].getElementsByTagName('video')[0];
      if ($video) {
        $video.setAttribute('autoplay', 'autoplay');
        $video.setAttribute('muted', '');
        $video.setAttribute('playsinline', '');
        $video.setAttribute('loop', '');
        $video.autoplay = true;
        $video.loop = true;
        $video.muted = true;
        $video.playsinline = true;
        $video.play();
      }
    });

    this.slider.on('transitionStart', () => {
      const $video = this.slider.slides[this.slider.previousIndex].getElementsByTagName('video')[0];
      if ($video) {
        $video.pause();
        $video.removeAttribute('autoplay');
        $video.autoplay = false;
      }
    });

    return this;
  }

  /**
   * Set default options and assign overrides/custom options from `updateOptions()`.
   *
   * @returns Swiper options object
   * @memberof Slider
   */
  public getOptions() {
    const options = {
      loop: true,
      speed: 800,
      simulateTouch: false,
      pagination: {
        el: '[data-slider-pagination]',
        type: 'bullets',
        clickable: true,
      },
      navigation: {
        nextEl: this.$next,
        prevEl: this.$prev,
      },
      keyboard: {
        enabled: true,
        onlyInViewport: true,
      },
    };
    return (this.options = Object.assign(options, this.updateOptions()));
  }

  /**
   * Additional options/overrides - to be used when extending.
   *
   * @returns Swiper options object
   * @memberof Slider
   */
  public updateOptions() {
    // const options = {
    //   speed: 2000,
    // };
    //
    // return options;
  }
}

export default Slider;
