import ModalDefault from './Modal.Default';
import Wizard from '../../components/Wizard';
import Heading from '../../components/Heading';

class ModalWizard extends ModalDefault {
  // wizard room ID
  wizardRoom: string;
  // wizard room time
  wizardTime: string;
  // wizard room time
  wizardPrice: string;
  // initialized wizard
  wizard: any;

  handleTriggerClick(e) {
    this.wizardRoom = e.target.dataset.wizardRoom;
    this.wizardTime = e.target.dataset.wizardTime;
    this.wizardPrice = e.target.dataset.wizardPrice;
    this.open();
    return this;
  }

  beforeOpen() {
    if (typeof this.wizardRoom === 'undefined') {
      this.wizardRoom = this.$triggers[0].dataset.wizardRoom;
    }
    if (typeof this.wizardTime === 'undefined') {
      this.wizardTime = this.$triggers[0].dataset.wizardTime;
    }
    if (typeof this.wizardPrice === 'undefined') {
      this.wizardPrice = this.$triggers[0].dataset.wizardPrice;
    }
    this.wizard = new Wizard(this.wizardRoom, this.wizardTime, this.wizardPrice, this);
    const $targetScene = this.$modal.querySelector(`[data-wizard-scene="${this.wizardRoom}"]`);
    const $heading = $targetScene.querySelector('[data-wizard-heading]');
    if ($heading) this.headingTweener = new Heading($heading);
    $targetScene.classList.add('is-up');
    super.beforeOpen();
    return this;
  }
}

export default ModalWizard;
