import { IVLDForm, IVLDField, IVLDFieldStateProperty } from './VLD.d';

import choicesJs from 'choices.js';

import VLDField from './VLD.Field';

/**
 * `VLDFieldSelect` Select field creation class, extended from `VLDField`.
 *
 * @module VLDFieldSelect
 * @extends {VLDField}
 */

class VLDFieldSelect extends VLDField {
  // initialiazed Choices object
  // selectChoices: any = null;
  selectChoices: any;

  constructor($field: HTMLInputElement | HTMLSelectElement, vldForm?: IVLDForm) {
    super($field, vldForm);
  }
  /**
   * Before proceeding with `mountField()` method, initialize `choicesJs`library
   * on HTML `<select>` with some default options.
   *
   * @returns {VLDFieldSelect} For chaining.
   * @memberof VLDFieldSelect
   */

  public beforeMount(): VLDFieldSelect {
    this.selectChoices = new choicesJs(this.$field, {
      searchEnabled: false,
      removeItemButton: false,
      shouldSort: false,
      itemSelectText: '',
    });
    const $choices = this.selectChoices.containerOuter.element;
    $choices.addEventListener('focus', () => this.handleFocus());
    $choices.addEventListener('blur', () => this.handleBlur());
    return this;
  }

  /**
   * Handle field focus event (add corresponding class names)
   *
   * @memberof VLDFieldSelect
   */

  public handleFocus() {
    this.updateState({ isFocused: true });
    this.selectChoices && this.selectChoices.showDropdown();
  }

  /**
   * Handle field blur event.
   * Mark field as not pristine (`isPristine: false`) upon blur, so it could start being validated.
   * Then start validating.
   *
   * @memberof VLDFieldSelect
   */

  public handleBlur() {
    this.updateState({
      isFocused: false,
      isPristine: false,
    });
    this.selectChoices && this.selectChoices.hideDropdown();
  }
}

export default VLDFieldSelect;
