import animejs from 'animejs';

import Modal from './Modal';
import Heading from '../../components/Heading';

class ModalDefault extends Modal {
  headingTweener: any;

  beforeMount() {
    const $heading = this.$modal.querySelector('[data-heading]');
    if ($heading) this.headingTweener = new Heading($heading);
    return this;
  }

  onOpen() {
    animejs.set(this.$modal, { visibility: 'visible' });
    animejs({
      targets: this.$modal,
      opacity: [0, 1],
      easing: 'easeOutQuad',
      duration: 400,
      complete: () => this.afterOpen(),
    });
    return this;
  }

  onClose() {
    animejs({
      targets: this.$modal,
      opacity: [1, 0],
      easing: 'easeOutQuad',
      duration: 400,
      complete: () => this.afterClose(),
    });
    return this;
  }

  beforeOpen() {
    super.beforeOpen();
    if (this.headingTweener) this.headingTweener.timeline.play();
    return this;
  }

  afterClose() {
    this.isActive = false;
    animejs.set(this.$modal, { visibility: 'hidden' });
    return this;
  }
}

export default ModalDefault;
