import swiper from 'swiper';
// import swiper from 'swiper/js/swiper.esm.browser.bundle';

import SliderDefault from './Slider.Default';
import SliderFeatured from './Slider.Featured';

import $$ from '../../toolkit/$$';
import camelize from '../../toolkit/camelize';

/**
 * Slider initializer factory, using Swiper library.
 *
 * @module SlidersController
 * @requires SlidersInstance
 */

class SlidersController {
  /**
   * Creates an instance of SlidersController.
   *
   * @memberof SlidersController
   * @returns An object of initialized SlidersController.
   */

  // A NodeList of '[data-slider]' HTML Elements:
  public $sliders: NodeListOf<HTMLElement>;

  // An array of initialized sliders:
  public sliders: any[];

  /**
   * Creates an instance of SlidersController.
   * Dynamically imports Swiper lib and if there are any `[data-slider]` in DOM,
   * call `mountSliders()` after import with imported module.
   * @memberof SlidersController
   */
  constructor() {
    this.$sliders = $$('[data-slider]');
    if (this.$sliders[0]) this.mountSliders(swiper);
    // if (this.$sliders[0]) {
    //   // tslint:disable-next-line:space-in-parens
    //   import(/* webpackChunkName:"swiper" */ 'swiper/dist/js/swiper')
    //     .then((swiperModule: any) => this.mountSliders(swiperModule.default));
    // }
  }

  /**
   * Loops through all `$sliders` and depending on type, call Slider constructors.
   *
   * @param {*} swiper Swiper module
   * @returns {SlidersController} For chaining metods
   * @memberof SlidersController
   */
  mountSliders(swiper) {
    this.sliders = [].forEach.call(this.$sliders, ($slider, i) => {
      const type = camelize($slider.dataset.slider);
      if (type === 'featured') return new SliderFeatured($slider, swiper, this, i);
      return new SliderDefault($slider, swiper, this, i);
    });
    return this;
  }
}

export default SlidersController;
