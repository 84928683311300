import * as GoogleMapsLoader from 'google-maps';

import Map from './Map';
// import MapCustom from './Maps.Map.Custom';

import $$ from '../../toolkit/$$';
import camelize from '../../toolkit/camelize';

/**
 * Google Maps initializer factory.
 *
 * @module MapsController
 * @requires MapsInstance
 */

class MapsController {
  /**
   * Creates an instance of MapsController.
   *
   * @memberof MapsController
   * @returns An object of initialized MapsController.
   */

  // A NodeList of '[data-map]' HTML Elements:
  public $maps: NodeListOf<HTMLElement>;

  // An array of initialized maps:
  public maps: any[];

  /**
   * Creates an instance of MapsController.
   * Dynamically imports Swiper lib and if there are any `[data-map]` in DOM,
   * call `mountMaps()` after import with imported module.
   * @memberof MapsController
   */
  constructor() {
    this.$maps = $$('[data-map]');
    if (this.$maps[0]) {
      GoogleMapsLoader['KEY'] = 'AIzaSyCIqmRyWTM_nDdgcV1N6jdoTn4pWw6fEJo';
      GoogleMapsLoader.load(google => this.mountMaps(google.maps));
    }
  }

  /**
   * Loops through all `$maps` and depending on type, call Map constructors.
   *
   * @param {*} Swiper Swiper module
   * @returns {MapsController} For chaining metods
   * @memberof MapsController
   */
  mountMaps(googleMaps) {
    this.maps = [].map.call(this.$maps, ($map, i) => {
      const type = camelize($map.dataset.map);
      // if (type === 'custom') return new MapCustom($map, Swiper, this, i);
      return new Map($map, googleMaps, this, i);
    });
    return this;
  }
}

export default MapsController;
