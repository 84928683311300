import ModalDefault from './Modal.Default';
import ModalPromo from './Modal.Promo';
import ModalWizard from './Modal.Wizard';

import $$ from '../../toolkit/$$';
import removeLocationHash from '../../toolkit/removeLocationHash';
import { fixBody, releaseBody } from '../../toolkit/fixBody';

/**
 * ModalsController initializer factory.
 *
 * @module ModalsController
 * @requires ModalsInstance
 */

class ModalsController {
  /**
   * Creates an instance of ModalsController.
   *
   * @memberof ModalsController
   * @returns An object of initialized ModalsController.
   */

  // A NodeList of '[data-modal]' HTML Elements:
  public $$modals: NodeListOf<HTMLElement>;

  // An array of mounted modals.
  public modals: any[];

  // An array of active modals in a queue.
  public activeModalsQueue: any[] = [];

  // Active modal object.
  public activeModal: any = null;

  // ID of an active modal
  public activeModalID: string = null;

  // Root url.
  public baseUrl: string = document.body.dataset.base;

  public options: {
    // If modals should layer on top or should close automatically before opening.
    closePrevious?: boolean;
  };

  /**
   * Creates an instance of ModalsController.
   * If there are any `[data-modal]` in DOM, call `mountModals()`.
   *
   * @memberof ModalsController
   */
  constructor(options?) {
    this.options = options ? options : {};
    this.$$modals = $$('[data-modal]');
    if (this.$$modals[0]) this.mountModals();
  }

  /**
   * Loops through all `$modals` and depending on type, call Modal constructors.
   *
   * @returns {ModalsController} For chaining metods
   * @memberof ModalsController
   */
  mountModals() {
    this.modals = [].map.call(this.$$modals, ($modal, i) => {
      const id = $modal.dataset.modal;
      if (id === 'wizard') return new ModalWizard($modal, this, i);
      if (id === 'promo') return new ModalPromo($modal, this, i);
      return new ModalDefault($modal, this, i);
    });

    document.addEventListener('keyup', (e: any) => {
      if (e.key === 'Escape' && this.activeModal) this.activeModal.close();
    });

    this.checkForID();

    this.afterMount();

    return this;
  }

  /**
   * Extra function to call after mounting all modals.
   *
   * @returns {ModalsController} For chaining.
   * @memberof ModalsController
   */
  public afterMount() {
    return this;
  }

  /**
   * A callback function fired just after modal.open() function.
   * Takes in passed down modal object. Controls hash/href.
   *
   * @param {*} modal A mounted modal object.
   * @returns {Modal} For chaining.
   * @memberof Modal
   */
  public onModalOpen(modal) {
    // If there were no modals open currently (queue is empty), fix body.
    if (this.activeModalsQueue.length === 0) fixBody();
    if (!this.options.closePrevious) {
      // If options.closePrevious is NOT set or set to FALSE, add target modal to the queue.
      this.activeModalsQueue.push(modal);
      // Else if options.closePrevious is set to TRUE and there is an other currently active modal.
    } else if (this.activeModal) {
      // Close previously active modal.
      this.activeModal.close();
    }
    // Assign activeModal as target modal.
    this.activeModal = modal;
    // Assign activeModalID as target modal's ID.
    this.activeModalID = modal.id;
    // Update URL.
    this.updateUrl();
    return this;
  }

  /**
   * A callback function fired just after modal.close() function.
   * Controls hashe/href.
   *
   * @returns {Modal} For chaining.
   * @memberof Modal
   */
  public onModalClose() {
    // First, remove last modal from the queue.
    this.activeModalsQueue.pop();
    if (this.activeModalsQueue.length > 0) {
      // If there are still modals in queue, get the last modal and assding current id.
      this.activeModal = this.activeModalsQueue[this.activeModalsQueue.length - 1];
      // Assign activeModalID as new acticeModal's ID.
      this.activeModalID = this.activeModal.id;
      // Update URL.
      this.updateUrl();
    } else {
      this.activeModalID = '';
      this.activeModal = null;
      removeLocationHash();
      releaseBody();
    }
    return this;
  }

  /**
   * Check URL for modal's ID and opens it, if found.
   *
   * @returns {ModalsController} For chaining.
   * @memberof ModalsController
   */
  public checkForID() {
    // Exctract needed strings.
    const { hash } = window.location;
    const id = hash.replace('#', '');
    // Search through all the moddals with '.find()', to find matching 'id'.
    const $targetModal = this.modals.find(modal => modal.id === id);
    // If target moadl was found, open it.
    if ($targetModal) $targetModal.open();

    return this;
  }

  /**
   * Update URL.
   *
   * @returns {ModalsController} For chaining.
   * @memberof ModalsController
   */
  private updateUrl() {
    // Add hash.
    location.hash = `#${this.activeModalID}`;
    return this;
  }
}

export default ModalsController;
