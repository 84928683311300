import Tweener from './Tweener';

class TweenerDefault extends Tweener {
  public addTweens() {
    this.timeline.add({
      targets: this.$tweener,
      translateY: [20, 0],
      opacity: [0, 1],
      duration: 500,
      easing: 'cubicBezier(0.44, 0.05, 0.55, 0.95)',
    });
    return this;
  }

  public onTimelineComplete() {
    super.onTimelineComplete();
    this.$tweener.classList.remove('_should-tween');
    return this;
  }
}

export default TweenerDefault;
