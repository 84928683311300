import VLDField from './VLD.Field';

/**
 * `VLDFieldCheckBox` Checkbox field creation class, extended from `VLDField`.
 *
 * @module VLDFieldCheckBox
 * @extends {VLDField}
 */

class VLDFieldCheckBox extends VLDField {
  /**
   * Function for checking if field has value.
   * It acts as sort of a getter.
   *
   * OVERRIDE:
   * In checkbox case, it checks not for `value.length`, but for `checked` property.
   *
   * @returns {boolean} State: true or false.
   * @memberof VLDFieldCheckBox
   */

  public hasValue(): boolean {
    return Boolean(this.$field.checked);
  }

  /**
   * Update field object's value and start validation.
   *
   * OVERRIDE:
   * In checkbox case, check not for value, but for `checked` property.
   * Also, don't check for pristinity and start validating immediately on interaction.
   *
   * @memberof VLDFieldCheckBox
   */

  public handleChange() {
    this.$field.value = this.$field.checked ? 'on' : '';
    this.updateState({
      hasValue: this.hasValue(),
      isValid: this.applyValidators(),
      hasError: !this.applyValidators(),
    });
  }
}

export default VLDFieldCheckBox;
