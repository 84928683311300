interface WindowWithGA extends Window {
  ga: any;
}

// SCSS
import '../scss/index.scss';

import turbolinks from 'turbolinks';
// import * as Turbolinks from 'turbolinks';

// POLYFILLS
// Custom POLYFILLS
import './toolkit/polyfills';

// Vendor POLYFILLS and initialization
import 'intersection-observer';
import 'objectFitPolyfill';
import cssVarsPonyfill from 'css-vars-ponyfill';
cssVarsPonyfill();

// Universal MODULES:
// import CookiebarBackend from './modules/CookiebarBackend';
import Player from './modules/Player';
import MapsController from './modules/maps/MapsController';
import ModalsController from './modules/modals/ModalsController';
import ParallaxsController from './modules/parallaxs/ParallaxsController';
import SlidersController from './modules/sliders/SlidersController';
import TweenersController from './modules/tweeners/TweenersController';

// Varios other TOOLS:
import $$ from './toolkit/$$';

const setAnchors = (url?) => {
  const trim = str => (str.charAt(str.length - 1) === '/' ? str.slice(0, -1) : str);
  const href = url || location.href;
  [].forEach.call($$('a'), ($a: HTMLAnchorElement) => {
    $a.classList[trim($a.href) === trim(href) ? 'add' : 'remove']('is-active');
    $a.parentElement.classList[trim($a.href) === trim(href) ? 'add' : 'remove']('is-active');
  });
};

if ($$('[data-navicon]')[0]) {
  $$('[data-navicon]').forEach(($navicon: HTMLElement) => {
    $navicon.addEventListener('click', () => {
      const navOpen = document.body.matches('.nav-open');
      document.body.classList[navOpen ? 'remove' : 'add']('nav-open');
    });
  });
}

const initApp = () => {
  new MapsController();
  new ModalsController();
  new ParallaxsController();
  new SlidersController();
  new TweenersController();

  setAnchors();

  if ($$('[data-header-observer]')[0]) {
    new IntersectionObserver((entries: any) => {
      const { isIntersecting } = entries[0];
      document.body.classList[isIntersecting ? 'remove' : 'add']('header-narrow');
    }).observe($$('[data-header-observer]')[0]);
  }

  if ($$('[data-player]')[0]) $$('[data-player]').forEach($p => new Player($p));

  if ($$('[data-categories-select]')[0]) {
    $$('[data-categories-select]').forEach(($select: HTMLSelectElement) => {
      $select.addEventListener('change', () => (location.href = $select.value));
    });
  }

  if ($$('[data-search-input]')[0]) {
    $$('[data-search-input]').forEach(($input: HTMLInputElement) => {
      $input.addEventListener('focus', () => $input.parentElement.classList.add('is-focused'));
      $input.addEventListener('blur', () => {
        setTimeout(() => $input.parentElement.classList.remove('is-focused'), 350);
      });
      document.body.addEventListener('keyup', (e: any) => {
        if (e.key === 'Escape') {
          setTimeout(() => $input.parentElement.classList.remove('is-focused'), 350);
        }
      });
    });
  }
};

const onLoad = () => {
  turbolinks.start();
  initApp();
};

document.addEventListener('turbolinks:load', initApp);

window.addEventListener('load', onLoad);
