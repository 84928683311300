import ModalDefault from './Modal.Default';

class ModalPromo extends ModalDefault {
  // should w
  showPromo: boolean;

  constructor($modal: HTMLElement, modalsController: any, i: number) {
    super($modal, modalsController, i);
    // reassign different id
    this.id = $modal.dataset.promo;
  }

  public beforeMount() {
    super.beforeMount();
    this.showPromo = Boolean(localStorage.getItem('promo') !== this.$modal.dataset.promo);
    return this;
  }

  public afterMount() {
    super.afterMount();

    if (this.showPromo) {
      document.addEventListener('mouseleave', () => {
        if (this.showPromo) this.startPromo();
      });

      if (this.$modal.dataset.promoDelay) {
        setTimeout(() => this.startPromo(), Number(this.$modal.dataset.promoDelay));
      }
    }
    return this;
  }

  public startPromo() {
    this.showPromo = false;
    localStorage.setItem('promo', this.id);
    if (!this.isActive) this.open();
  }
}

export default ModalPromo;
